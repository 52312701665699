













































































































import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';
import NumberValue from '@/tasks/components/NumberValue.vue';
import {defineComponent} from '@vue/composition-api';

export default defineComponent({
  name: 'uOttawa1321Lab3Question3',
  components: {
    NumberValue,
    StembleLatex,
    CalculationInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    const browserLanguage = navigator.language.split('-')[0]; // Extract the language code
    return {
      inputs: {
        massRecoveredA: null,
        massRecoveredB: null,
        sampleCalc: null,
        language: browserLanguage === 'fr' ? 'fr' : 'en', // Set the initial language based on browser preference
      },
      calculations: [] as File[],
    };
  },
  computed: {
    solWater(): number {
      return (this as any).taskState.getValueBySymbol('solWater').content;
    },
    solEther(): number {
      return (this as any).taskState.getValueBySymbol('solEther').content;
    },
    massY(): number {
      return (this as any).taskState.getValueBySymbol('massY').content;
    },
    attachments(): File[] {
      return [...this.calculations];
    },
  },
});
